.users-tab {
  padding-top: 24px;
  padding-bottom: 24px;
}

.access-pond {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 24px;

  & > div {
    width: 100%;
    border: 1px solid #d9d9d9;
  }

  .cancel-btn {
    margin-right: 8px;
  }
}

.access-pond-section {
  .item-selector {
    width: 100%;
  }
}

.add-permissions-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.edit-buttons {
  display: flex;
}

.column-tooltip {
  display: flex;
  align-items: center;
}

div.access-group {
  margin-top: 24px;
}

.column-folders-select {
  width: 100%;
}

.select-field {
  width: 100%;
}

ul {
  padding-left: 0;
}

.add-folder-btn {
  min-width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.access-list {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 0;

  & > li:not(:first-child) {
    margin-top: 16px;
  }
}

.access-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div:first-child {
    width: 70%;
  }

  & > div:nth-child(2) {
    width: 25%;
  }

  & > div:last-child {
    display: flex;
    justify-content: center;
    width: 5%;
  }
}

.disabled-block {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff97;
    z-index: 2;
  }
}
