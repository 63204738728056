div.static-card {
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
  border: 1px solid #d9d9d9;
}

.static-card-descr {
  padding: 16px 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  border-bottom: 1px solid #f0f0f0;
}

.static-card-body {
  padding: 24px;
}

.descr-tooltip > div > span:first-child::after {
  display: none;
}
