@import "./core/fonts.scss";

body {
  font-family: "Poppins";
  background-color: #f1f2f5;
}

ul {
  list-style: none;
}

#root {
  height: 100%;
  background-color: #f0f2f5;
}

.app {
  height: 100%;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.browse-files-link {
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0 8px;
  font-size: medium;
  font-weight: 400;
  position: relative;
  top: 1px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
