.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.spinner-pond {
  margin-top: 250px;
}
