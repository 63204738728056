.tooltip-box {
  display: flex;
  align-items: center;

  .tooltip-title {
    margin-left: 4px;
    vertical-align: middle;

    svg {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
