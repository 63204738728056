// TODO remove it after refactoring
.get-label {
  display: flex;
  color: #8c8c8c;
  justify-content: space-between;

  & > span:first-child {
    margin-right: 2px;
  }
}
