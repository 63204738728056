.terms-of-use {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__checkbox {
    margin-top: 8px;

    &:first-of-type {
      margin-top: 16px;
    }

    & > label {
      margin-right: 8px;
    }
  }
}
