div.get-credentials {
  border: 1px solid #d9d9d9;
  margin: 0 24px;
  margin-top: 24px;

  & > div:first-child {
    border-bottom: 1px solid #d9d9d9;
  }

  & > div:nth-child(2) {
    display: none;
  }
}

div.credentials-card {
  margin-top: 24px;
  margin-right: 24px;
  margin-left: 24px;
  border: 1px solid #d9d9d9;

  .update-btn {
    margin-right: 8px;
  }

  &-buttons {
    display: flex;
    gap: 16px;
  }
}
