.header-right {
  display: flex;
  align-items: center;
  position: relative;
  .header-support-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    margin-right: 16px;
    cursor: pointer;
    border: 1px solid #d9d9d9;
    border-radius: 100%;

    .header-support-button {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
      }
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.support-menu-modal .cancel-button {
  display: none;
}
