div.python-connect {
  margin-top: 24px;
  border: 1px solid #d9d9d9;

  &-segmented,
  &-text {
    border-bottom: 1px solid #f0f0f0;
    padding: 24px;
  }

  ul {
    padding-left: 19px;
    margin-bottom: 0;

    li {
      position: relative;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);
    }

    li::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      background-color: #000;
      border-radius: 100%;
    }
  }

  .python-connect-descr {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-body {
    padding: 24px;

    .number {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 16px;
      background-color: #1890ff;
      color: #fff;
      border-radius: 10px;
      margin-right: 12px;
    }

    .bold {
      font-weight: 500;
      font-size: 14px;
      margin-right: 12px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  & > div:last-child {
    padding: 0;
  }
}
