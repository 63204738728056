.cookie-banner {
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: auto;
  padding: 0 16px;

  animation: 1.5s ease hide-banner;
  transform: translateY(100vh);
  visibility: hidden;

  &.active {
    animation: 0.5s ease show-banner;
    transform: translateY(0);
    visibility: visible;
  }

  &__container {
    width: fit-content;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 16px 24px;
    border-radius: 16px 16px 0 0;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  }

  &__button {
    align-self: center;
    margin-top: 16px;
  }

  @keyframes show-banner {
    from {
      transform: translateY(100vh);
      visibility: hidden;
    }
    to {
      transform: translateY(0);
      visibility: visible;
    }
  }

  @keyframes hide-banner {
    from {
      transform: translateY(0);
      visibility: visible;
    }
    to {
      transform: translateY(100vh);
      visibility: hidden;
    }
  }
}
