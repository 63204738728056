.add-policy {
  .breadcrumbs {
    background-color: #fff;
    position: fixed;
    z-index: 5;
    top: 63px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #f0f2f5;
  }
  .add-pond-body {
    margin-top: 166px;
    padding: 24px;

    .alert {
      margin-bottom: 24px;
    }

    .add-pond-general {
      margin-bottom: 24px;
    }
    .agree-terms {
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .colid-card {
    margin-bottom: 24px;
    .colid-header {
      padding: 14px 24px;
      box-shadow: inset 0px -1px 0px #f0f0f0;
    }
    .colid-body {
      margin: 24px 24px 48px 24px;
      .pId-input {
        max-width: 645px;
        margin-top: 8px;
      }
      .select-colid {
        width: 100%;
      }
      .first-line {
        display: flex;
        & > div {
          width: 33%;
          margin-right: 16px;
        }
      }
      .second-line {
        margin-top: 24px;
        .fields {
          display: flex;
          & > div {
            width: calc(33% - 10px);
            margin-right: 16px;
          }
          & > label {
            display: flex;
            align-items: center;
            & > span {
              top: 0;
            }
          }
        }
      }
    }
  }
  .add-pond-additional {
    margin: 24px 0;
    .additional-item {
      width: 33%;
      margin-right: 24px;
      .pond-img {
        width: 48px;
        height: 48px;
        margin-right: 16px;
      }
      .colid {
        margin: 14px 16px 0 0;
        width: 47px;
        height: 19px;
      }
    }
    .last {
      margin-right: 0;
    }
  }
}
