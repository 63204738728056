.pond-page-card {
  .activated-item {
    position: relative;
    left: 16px;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: -16px;
      width: 6px;
      height: 6px;
      background-color: #d9d9d9;
      border-radius: 100%;
      transform: translateY(-50%);
    }
  }

  .colid-block {
    display: flex;
    align-items: center;
    gap: 32px;

    a {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }

  .activated {
    &::before {
      background-color: #52c41a;
    }
  }
}

.change-encryption {
  margin-left: 9px;
}

.general-modal-icon {
  svg {
    color: #ffc53d;
  }
}
