.code-viewer {
  &__container {
    margin: 4px 0;
    width: fit-content;
    max-width: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  &__header {
    padding: 12px;
    background-color: #40414f;
    color: #ececf1;
    font-size: 12px;
    line-height: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    padding: 12px 24px;
    background-color: #050509;
    text-wrap: nowrap;
    overflow-x: auto;

    &-text {
      word-spacing: 12px;
      color: #ffffff;
    }
  }
}
