div.info-alert {
  align-items: flex-start;
  margin-bottom: 10px;

  & > span {
    margin-top: 5px;
  }
}

span.copy-icon {
  color: #1890ff;
  cursor: pointer;
  margin-left: 9px;
}

div.machine-users {
  margin-right: 24px;
  margin-left: 24px;
  margin-top: 24px;
  border: 1px solid #d9d9d9;

  &-item {
    border: 1px solid #d9d9d9;

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    & > div:first-child {
      background: #fafafa;
    }

    &-ssh {
      margin-top: 16px;
      border: 1px solid #d9d9d9;

      & > .ant-card-head {
        display: flex;
        align-items: center;

        & > .ant-card-head-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;

          & > .ant-card-head-title,
          & > .ant-card-extra {
            padding: 0;
          }
        }
      }

      & .ant-descriptions-item-content {
        width: 80%;
      }

      & > .ant-card-body {
        padding: 16px 24px 0;
      }

      & > div:first-child {
        background: #fafafa;
      }
      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.ssh-collapse {
  & .ant-collapse-header {
    padding: 8px 24px !important;
  }

  & > div:first-child {
    background: #e5f2ff;
  }

  & .ant-collapse-content {
    padding: 16px;
    background: #f4f9ff;
  }
}

.prefix-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  border: 1px solid #d9d9d9;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 16px;

  &__item {
    background-color: #fff;
    width: 100%;
    display: flex;
    gap: 12px;
  }
}
