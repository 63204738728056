.shh-key-form {
  &__item {
    width: 100%;

    &-alert.ant-alert {
      margin-bottom: 24px;
      padding: 15px;
      text-align: justify;

      & > span.ant-alert-icon {
        font-size: 20px;
      }
    }

    & > .ant-form-item-row {
      display: flex;
      flex-direction: column;

      & > .ant-form-item-label {
        text-align: start;
      }
    }
  }

  & .ant-picker {
    width: 50%;
  }
}
