.action-buttons {
  display: flex;
  * {
    margin-right: 10px;
  }
}

.edit-description {
  overflow: hidden;
}

.content {
  width: 100%;
  overflow: auto;
  resize: none;
  padding: 0;

  ul {
    padding-left: 2rem;
    list-style: disc !important;
  }

  li {
    line-height: 1.6;
  }
}

.standart {
  max-height: 150px;
  overflow: scroll;
  border: none;
}

*::-webkit-scrollbar {
  width: 8px;
  min-height: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 99px;
  background: #adb5bd;
}
