div.other-connectors-wrapper {
  padding: 24px;

  p {
    padding: 0;
  }
}

.other-connectors {
  display: flex;
  align-items: center;
  padding-left: 30px;
  margin-top: 21px;

  &-item:not(:first-child) {
    margin-left: 15px;
  }

  &-item:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }
}

div.other-connect {
  margin-top: 24px;
  border: 1px solid #d9d9d9;
}
