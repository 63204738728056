.connect-tab {
  padding: 24px;
}

div.science-access {
  margin-top: 24px;
  border: 1px solid #d9d9d9;
}

.python-collapse-description {
  margin-top: 24px;
  margin-left: 7px;

  td {
    padding-bottom: 0 !important;
  }

  td > div > span {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
}

.snippet-padding {
  padding-left: 30px;
}

.snippet-mb {
  margin-bottom: 20px;
}

.python-collapse-description.second {
  margin-top: 8px;
}

.athena-aws-segment {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  padding: 14px 24px;

  .athena-aws-guide {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    svg {
      margin-right: 8px;
    }
  }
}

.blue-link {
  color: #1890ff;
  margin: 0 3px;
  cursor: pointer;
}

div.block-collapse {
  margin-top: 24px;

  &.small {
    & .ant-collapse-header {
      padding: 8px 24px !important;
    }
  }

  &.no-margin {
    margin: 0px;
  }

  .descr {
    padding: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }

  .links {
    padding: 24px;

    &-item {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      svg {
        margin-right: 8px;
      }
    }

    &-item:not(:first-child) {
      margin-top: 4px;
    }
  }

  .lists {
    padding: 0 24px 24px 24px;
  }

  p {
    margin-bottom: 0;
    padding: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
  }

  p.block-collapse-descr {
    padding: 24px;
  }

  & > div.block-collapse-panel > div.ant-collapse-header {
    display: flex;
    align-items: center;
    padding-right: 24px;
    padding-left: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  & > div.block-collapse-panel > div.ant-collapse-header > div:first-child {
    display: none;
  }
}

.other-connect-segmented {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.other-connect-segmented,
.science-connect-segmented {
  padding: 24px;
  border-bottom: 1px solid #f0f0f0;
}

.other-connect {
  margin-top: 24px;

  &-body {
    padding: 24px;
  }

  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 16px;
    background-color: #1890ff;
    color: #fff;
    border-radius: 10px;
    margin-right: 12px;
  }

  .bold {
    font-weight: 500;
    font-size: 14px;
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.85);
  }
}

.block-collapse-panel {
  .science-connect-segmented {
    padding: 24px;
    border-bottom: 1px solid #f0f0f0;
  }

  .science-connect-body {
    padding: 24px;

    .links {
      padding: 0;

      &-item {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        svg {
          margin-right: 8px;
        }
      }

      &-item:not(:first-child) {
        margin-top: 4px;
      }
    }
  }

  & > div:last-child > div {
    padding: 0;
  }
}

div.sftp-connect {
  &__card {
    margin-top: 24px;
    border: 1px solid #d9d9d9;

    & .ant-card-head {
      background-color: #fafafa;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  &__container {
    padding: 24px;

    & p {
      padding: 0;
    }
  }
}
