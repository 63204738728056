.ponds-list {
  .spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .ponds-table {
    padding: 24px;
    background-color: #f1f2f5;

    & > div > div > div > ul li button {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .name {
      display: flex;
      align-items: center;
      span {
        margin-left: 5px;
        color: #1890ff;
      }
    }
    .empty-text {
      color: rgba(0, 0, 0, 0.85);
      margin: 8px;
    }
  }
}
