div.prefix-name:not(:first-child) {
  margin-top: 20px;
}

div.access-fields-wrapper {
  display: flex;
  align-items: center;

  & > input {
    width: 70% !important;
  }

  & > div {
    width: 25% !important;
    margin-left: 8px;
  }

  & > button {
    display: flex;
    justify-content: center;
    width: 5% !important;
  }
}

div.access-fields-wrapper:not(:first-child) {
  margin-top: 8px;
}

div.access-pond-roles {
  margin-top: 24px;
}

.modal-error-role
  > div:nth-child(2)
  > div:first-child
  > div:first-child
  > div:first-child
  > div {
  padding-left: 40px;
}
