div.athena-properties {
  border: 1px solid #d9d9d9;
  margin-top: 24px;

  & > div:first-child {
    border-bottom: 1px solid #d9d9d9;
  }

  &-extra {
    display: flex;
    align-items: center;

    span {
      margin-right: 8px;
    }
  }

  .crawler-btn {
    margin-left: 15px;
  }

  .state {
    position: relative;
    padding-left: 14px;

    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: #ddd;
    }
  }

  .crawler-ready {
    &::before {
      background-color: #52c41a;
    }
  }

  .crawler-running {
    &::before {
      background-color: #1890ff;
    }
  }

  .crawler-stoping {
    &::before {
      background-color: #ff4d4f;
    }
  }

  .crawler-table {
    padding-left: 0;

    li {
      position: relative;
      padding-left: 13px;

      &::before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 3px;
        border-radius: 100%;
        background-color: #000;
      }
    }
  }
}

.snippet-wrapper {
  display: flex;
  column-gap: 24px;
  padding: 24px;

  .snippet-block {
    background-color: #f4f4f4;
    border-radius: 2px;
    padding: 24px;
    position: relative;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  & > div {
    width: 50%;
  }
}

.snippet-padding-plus {
  padding-left: 50px;
}

.get-athena-config {
  margin-left: 10px;
}

span.athena-properties-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 8px;
}

.snippet-padding {
  padding-left: 20px;
}

div.athena-access-block {
  margin-top: 24px;
  border: 1px solid #d9d9d9;
}

.athena-access-descr {
  padding: 24px;
  margin-bottom: 0;
}

.athena-alert {
  padding: 14px 24px;
  border-bottom: 1px solid #f0f0f0;
}

.alert-bold {
  font-weight: 700;
}

.athena-modal-icon > svg {
  fill: red;
}
